<template>
  <el-card shadow="never">
    <div slot="header" class="cardheader" style="padding:5px,10px">
      <span style="font-weight:bold;">材料通知单列表</span>
      <div class="searchdiv1">
        <input
          class="searchinput"
          type="text"
          v-model="keyword"
          placeholder="编号 | 合同编号 | 合同名称 | 乙方签约人 | 库房 | 规格"
          @keyup.enter="getKeyword"
        />
        <button class="searchbutton" @click="getKeyword">搜索</button>
      </div>
    </div>

    <cailiaotongzhidan-table
      :tableData="cailiaotongzhidanTableData"
      :config="config"
      :userType="usertype"
      :isShowHeyueField="false"
      @changePage="getCailiaotongzhidanByID"
      @zuofei="zuofeiCailiaotongzhidan"
      @wuziAudit="wuziAuditCailiaotongzhidan"
      @caiwuAudit="caiwuAuditCailiaotongzhidan"
      @managerConfirm="confirmCailiaotongzhidan"
    ></cailiaotongzhidan-table>
  </el-card>
</template>

<script>
import utils from '@/assets/js/utils'
import CailiaotongzhidanTable from '../cailiaoTongzhidan/CailiaotongzhidanTable'

export default {
  components: {
    CailiaotongzhidanTable
  },

  data() {
    return {
      usertype: '',
      currentContract: 'all',
      cailiaotongzhidanTableData: [],
      keyword: '',
      config: {
        page: 1,
        total: 30,
        loading: false
      }
    }
  },
  methods: {
    //获取材料通知单信息
    getCailiaotongzhidanByID() {
      let tongzhidans = []
      console.log('cailiao tongzhidan by id')
      const api = '/getCailiaotongzhidanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract,
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.cailiaotongzhidanTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              let total_amount = parseInt(item.in_amount) + parseInt(item.add_amount)
              item.zujin_heji = item.zujin_danjia * total_amount
              item.yajin_heji = item.yajin_danjia * total_amount
              item.yunfei_heji = item.yunfei_danjia * total_amount
              item.chuban_heji = item.zujin_heji + item.yajin_heji + item.yunfei_heji + parseInt(item.diaozhuangfei_heji)

              this.cailiaotongzhidans.push(item.code)

              return item
            })
            this.chukuForm.cailiaotongzhidans = tongzhidans
            console.log('getCailiaotongzhidanByID', this.cailiaotongzhidanTableData)
            console.log('材料通知单数量', this.cailiaotongzhidanTableData.length)
            console.log('材料通知单编号', this.cailiaotongzhidans)

            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计

            //按材料、材料规格排序
            newArray = newArray.concat(this.cailiaotongzhidanTableData)
            console.log('newArray----------------:', newArray)

            //1. 排序
            this.cailiaotongzhidanTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序
              if (a.cailiao === b.cailiao) {
                return a.cailiaoguige < b.cailiaoguige ? -1 : 1
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })

            console.log('this.cailiaotongzhidanTableData', this.cailiaotongzhidanTableData)
            //2. 计算小计和总计
            let array = this.cailiaotongzhidanTableData
            // let newArray = [array[0]]

            let prior = array[0]
            let xiaoji = parseInt(prior.in_amount) + parseInt(prior.add_amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.in_amount) + parseInt(curr.add_amount)
              if (curr.cailiao_name === prior.cailiao_name && curr.cailiaoguige_name === prior.cailiaoguige_name) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.in_amount) + parseInt(curr.add_amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle = prior.cailiao_name + '-' + prior.cailiaoguige_name + ' ' + '小计'
                let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.in_amount) + parseInt(curr.add_amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, in_amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.cailiaotongzhidanTableData = newArray
            console.log('材料通知单数量（处理后）', this.cailiaotongzhidanTableData.length)
          } else {
            console.log('材料通知单合约信息为空！')
          }
        })
    },
    //增加材料通知单
    addCailiaotongzhidan() {
      this.cailiaotongzhidanForm = {}
      this.cailiaotongzhidan_operateType = 'add'
      this.cailiaotongzhidanDlg_isShow = true
    },
    //材料通知单表单提交
    submitCailiaotongzhidanForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        console.log('add:' + this.Type)
        if (!this.cailiaotongzhidanForm.contract) this.cailiaotongzhidanForm.contract = this.currentContract
        this.cailiaotongzhidanForm.added_person = this.user_realname
        // this.chukuForm.added_person_time = utils.getDateTime()
        console.log('cailiaotongzhidanForm:', this.cailiaotongzhidanForm)
        this.$axios.post('/addCailiaotongzhidan/', this.cailiaotongzhidanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加材料通知单成功!',
              duration: 2000
            })
            this.cailiaotongzhidanDlg_isShow = false
            this.getCailiaotongzhidanByID()
          } else {
            this.$message({
              type: 'warning',
              message: '添加失败',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    },
    //作废材料通知单信息
    zuofeiCailiaotongzhidan(row) {
      this.$confirm('此操作将作废此材料通知单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/zuofeiCailiaotongzhidan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getCailiaotongzhidanByID()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //获取调拨单信息
    getDiaobodanByID() {
      console.log('调拨单 by id')
      const api = '/getDiaobodanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.diaobodanTableData = res.data.data.map(item => {
              item.contract_hetong_gongdi = item.contract_id + '#' + item.contract_project
              item.added = item.added_person + '\n' + utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')

              let in_amount = parseInt(item.amount) - parseInt(item.out_amount)
              item.in_amount = in_amount
              return item
            })
          } else {
            console.log('调拨单合约信息为空！')
          }
        })
    },
    //增加调拨单
    addDiaobodan() {
      this.diaobodanForm = {}
      this.diaobodan_operateType = 'add'
      this.diaobodanDlg_isShow = true
    },
    //调拨单表单提交
    submitDiaobodanForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        console.log('add:' + this.Type)
        if (!this.diaobodanForm.contract) this.diaobodanForm.contract = this.currentContract
        this.diaobodanForm.added_person = this.user_realname
        // this.chukuForm.added_person_time = utils.getDateTime()
        console.log('diaobodanForm:', this.diaobodanForm)
        this.$axios.post('/addDiaobodan/', this.diaobodanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加调拨单成功!',
              duration: 2000
            })
            this.diaobodanDlg_isShow = false
            this.getDiaobodanByID()
          } else {
            this.$message({
              type: 'warning',
              message: '添加失败',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    },
    //删除调拨单信息
    deleteDiaobodan(row) {
      this.$confirm('此操作将永久删除此调拨单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteDiaobodan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getDiaobodanByID()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //获取物资系统出库信息
    getDataChukubyID() {
      const api = '/getChukuxinxisbyID/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.chukuTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
            console.log('chukuTableData', this.chukuTableData)
            console.log('this.chukuTableData', this.chukuTableData)
            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计
            if (this.kufangchuku_order === 1) {
              //按出库日期、录入时间排序
              newArray = newArray.concat(this.chukuTableData)
              console.log('newArray----------------:', newArray)
            }

            //1. 排序
            this.chukuTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序，如果材料规格相同，按照添加时间倒序
              if (a.cailiao === b.cailiao) {
                if (a.cailiaoguige === b.cailiaoguige) {
                  let a_date = utils.stringToDate(a.added_person_time)
                  let b_date = utils.stringToDate(b.added_person_time)
                  return a_date < b_date ? 1 : -1
                } else {
                  return a.cailiaoguige < b.cailiaoguige ? -1 : 1
                }
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })
            if (this.kufangchuku_order === 2) {
              //按材料、材料规格排序
              newArray = newArray.concat(this.chukuTableData)
              console.log('newArray----------------:', newArray)
            }
            console.log('this.chukuTableData', this.chukuTableData)
            //2. 计算小计和总计
            let array = this.chukuTableData
            // let newArray = [array[0]]
            // let newArray = [] //存储小计和合计
            let prior = array[0]
            let xiaoji = parseInt(prior.amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.amount)
              if (curr.cailiao_name === prior.cailiao_name && curr.cailiaoguige_name === prior.cailiaoguige_name) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, amount: xiaoji, type: '2' } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle = prior.cailiao_name + '-' + prior.cailiaoguige_name + ' ' + '小计'
                let temp = { cailiao_name: xiaojiTitle, amount: xiaoji, type: '2' }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, amount: xiaoji, type: '2' }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.chukuTableData = newArray
          } else {
            console.log('库房出库信息为空！')
            this.chukuTableData = []
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加库房出库信息
    addChuku() {
      this.chukuForm = {}
      this.fileListChuku = []
      this.chuku_operateType = 'add'
      this.chukuDlg_isShow = true
    },

    //删除库房出库信息
    delKufangchuku(row) {
      this.$confirm('此操作将永久删除此库房出库信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteKufangchuku/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getKufangchuku()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //提交库房出库表单
    submitwuziChukuForm(formData) {
      //增加库房出库
      this.chukuForm = formData
      this.chukuForm.contract = this.currentContract
      this.chukuForm.added_person = this.user_realname

      console.log('add chukuForm 从form组件取回的数据:', this.chukuForm)
      this.$axios.post('/addChukuxinxi/', this.chukuForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加库房出库信息成功!',
            duration: 2000
          })
          this.chukuDlg_isShow = false
          this.getDataChukubyID()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetChukuForm() {
      this.chukuDlg_isShow = false
      this.fileListChuku = []
    },
    //在table中显示图片
    showPhotosinTableChuku(row) {
      this.chukuphotosurls = []
      let photos = row.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let url = 'http://ln-jz.cn:8000/' + strs[i] //服务器
        // let url = strs[i] //本地

        this.chukuphotosurls.push(url)
      }
      console.log(this.chukuphotosurls)
      this.chukuPhotos_isShow = true
    },
    //在form中显示之前上传过的图片
    showPhotosinFormChuku(row) {
      let photos = row.photos
      console.log('photos', photos)
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let photo = {}
        let str = strs[i].split('/')
        let name = str[str.length - 1]
        photo['name'] = name
        photo['url'] = strs[i]
        this.fileListChuku.push(photo)
      }
      console.log('fileListChuku', this.fileListChuku)
    },
    /* 原出库代码
    //增加出库信息
    addChuku() {
      this.chukuForm = {}
      this.chuku_operateType = 'add'
      this.chukuDlg_isShow = true
    },
    //出库表单提交
    submitwuziChukuForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        console.log('add:' + this.Type)
        if (!this.chukuForm.chuzuheyue) this.chukuForm.chuzuheyue = this.currentContract
        this.chukuForm.added_person = this.user_realname
        this.chukuForm.added_person_time = utils.getDateTime()
        console.log('chukuForm:', this.chukuForm)
        this.$axios.post('/addChukuxinxi/', this.chukuForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加出库信息成功!',
              duration: 2000
            })
            this.chukuDlg_isShow = false
            this.getDataChukubyID()
          } else {
            this.$message({
              type: 'warning',
              message: '添加失败,请检查出库数量是否超过目前在库数',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    },
    */
    //获取回库信息
    getDataHuikubyID() {
      this.$axios
        .get('/getHuikuxinxisbyID/', {
          params: {
            heyueid: this.currentContract
          }
        })
        .then(res => {
          console.log(res.data)
          if (res.data.code === 1000) {
            this.huikuTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              return item
            })
          } else {
            console.log('出库信息为空！')
          }
        })
    },
    //获取损耗信息
    getDataSunhaobyID() {
      this.$axios
        .get('/getSunhaoxinxisbyID/', {
          params: {
            heyueid: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.sunhaoTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              return item
            })
          } else {
            console.log('损耗信息为空！')
          }
        })
    },

    //增加回库信息
    addHuiku() {
      this.huikuForm = {}
      this.huiku_operateType = 'add'
      this.huikuDlg_isShow = true
    },
    //增加损耗信息
    addSunhao() {
      this.sunhaoForm = {}
      this.sunhao_operateType = 'add'
      this.sunhaoDlg_isShow = true
    },

    /*
    物资审核
    */
    wuziAuditCailiaotongzhidan(row) {
      this.$confirm('确认通过物资审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/wuziAuditCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '物资审核成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消物资审核'
          })
        })
    },

    /*
    财务审核
    */
    caiwuAuditCailiaotongzhidan(row) {
      this.$confirm('确认通过财务审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/caiwuAuditCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '财务审核成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消财务审核'
          })
        })
    },
    /*
    分公司经理确认
    */
    confirmCailiaotongzhidan(row) {
      this.$confirm('确认通过确认吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/confirmCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '确认成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    },

    //回库表单提交
    submitHuikuForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        if (!this.huikuForm.chuzuheyue) this.huikuForm.chuzuheyue = this.currentContract
        this.huikuForm.added_person = this.user_realname
        this.huikuForm.added_person_time = utils.getDateTime()
        console.log('huikuForm:', this.huikuForm)
        this.$axios.post('/addHuikuxinxi/', this.huikuForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加回库信息成功!',
              duration: 2000
            })
            this.huikuDlg_isShow = false
            this.getDataHuiku()
          } else {
            this.$message({
              type: 'warning',
              message: '添加失败,请检查该项目是否存在',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    },

    //损耗表单提交
    submitSunhaoForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        if (!this.sunhaoForm.chuzuheyue) this.sunhaoForm.chuzuheyue = this.currentContract
        this.sunhaoForm.added_person = this.user_realname
        this.sunhaoForm.added_person_time = utils.getDateTime()
        console.log('sunhaoForm:', this.sunhaoForm)
        this.$axios.post('/addSunhaoxinxi/', this.sunhaoForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加损耗信息成功!',
              duration: 2000
            })
            this.sunhaoDlg_isShow = false
            this.getDataSunhao()
          } else {
            this.$message({
              type: 'warning',
              message: '添加失败,请检查损耗数量是否超过目前在库数',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    }
  },
  created() {
    this.getHeyueInfo()
    this.getDataAll()
    this.getContractDetail()
  }
}
</script>

<style scoped>
.center {
  background-color: #ffffff;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 16px;
  margin-bottom: 5px;
}
.title {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 16px;
  color: white;
}
.header-div {
  display: flex;
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  justify-content: space-between;
  background-color: rgb(116, 168, 190);
}
.button-div {
  padding-left: 20px;
}
</style>
